import {Button, Form, Modal, Select} from 'antd';
import React, {useContext, useEffect, useState} from 'react';
import DisabledContext from 'antd/es/config-provider/DisabledContext';
import DateInput, {FormattedDate} from '../DateInput';
import CoreboxImageInput from "./CoreboxImageInput";
import dayjs from "dayjs";
import {Option} from "antd/es/mentions";
import {COREBOX_PHOTO_SIDE} from "./CoreboxImageGallery";
import {useWatch} from "antd/es/form/Form";

const CoreboxImagesInput = ({id, showVersions, value, onChange}) => {
        const [modalOpen, setModalOpen] = useState(false);
        const [disabledOkButton, setDisabledOkButton] = useState(true);
        const [form] = Form.useForm()
        const disabled = useContext(DisabledContext)
        const parentForm = Form.useFormInstance()

        const handleValuesChange = (_, allValues) => {
            setDisabledOkButton(!(allValues.photos.length > 0 && allValues.photos.every((photo) => photo.dataBase64)))
        }

        const internalPhotos = useWatch('photos', form) || []
        const photos = useWatch('photos') || []
        const changedDate = useWatch('changedDate') || []
        const availableSides = Object.keys(COREBOX_PHOTO_SIDE).filter((side) => !internalPhotos?.find(photo => `${photo.photoSide}` === side))

        useEffect(() => {
            form.resetFields()
            setDisabledOkButton(true)
        }, [form, modalOpen])

        const handleFinish = (values) => {
            const newPhotos = values.photos.map((photo) => ({...photo, changedDate: values.changedDate}))
            parentForm.setFieldsValue({changedDate: values.changedDate, photos: newPhotos})

            setModalOpen(false)
        }

        const label = photos.length ? 'Add new version' : 'Add images'
        const showSelectSide = photos.length ? false : internalPhotos?.length < 4 && internalPhotos.every((photo) => photo.dataBase64)
        const previousVersionDate = changedDate ? dayjs(changedDate) : null

        return (
            <>
                <div className='flex w-full justify-between'>
                    <Form.List name="photos">
                        {(fields, {add, remove}) => (
                            <>
                                {fields.map((field) => (
                                    <Form.Item name={field.name}
                                               label={COREBOX_PHOTO_SIDE[parentForm.getFieldValue(['photos', field.name]).photoSide]}>
                                        <CoreboxImageInput showVersions={showVersions} disabled id={id}/>
                                    </Form.Item>
                                ))
                                }
                            </>
                        )}
                    </Form.List>
                    {
                        photos.length > 0 &&
                        <Form.Item required label='Changed date' name="changedDate">
                            <FormattedDate useCoreboxFormat={true}/>
                        </Form.Item>
                    }
                </div>
                {!disabled && <Button onClick={() => setModalOpen(true)}>{label}</Button>}
                {
                    modalOpen &&
                    <Modal
                        open={modalOpen}
                        title={label}
                        onCancel={() => setModalOpen(false)}
                        onOk={form.submit}
                        okButtonProps={{disabled: disabledOkButton}}
                    >
                        <Form
                            form={form}
                            initialValues={{
                                photos: photos.map(({photoSide}) => ({photoSide})),
                                changedDate: new Date()
                            }}
                            layout='vertical'
                            onFinish={handleFinish}
                            onValuesChange={handleValuesChange}
                        >
                            <Form.List name="photos">
                                {(fields, {add, remove}) => (
                                    <>
                                        {fields.map((field) => (
                                            <Form.Item name={field.name}
                                                       label={COREBOX_PHOTO_SIDE[form.getFieldValue(['photos', field.name]).photoSide]}>
                                                <CoreboxImageInput photos={photos}
                                                                   remove={photos.length ? null : () => remove(field.name)}/>
                                            </Form.Item>
                                        ))
                                        }
                                        {showSelectSide &&
                                        <Select
                                            placeholder="Select a side"
                                            onSelect={(selectedSide) => {
                                                add({photoSide: selectedSide})
                                            }}
                                            dropdownMatchSelectWidth={false}
                                        >
                                            {availableSides.map(side => (
                                                <Option key={side} value={side}>{COREBOX_PHOTO_SIDE[side]}</Option>
                                            ))}
                                        </Select>
                                        }
                                    </>
                                )}
                            </Form.List>
                            <Form.Item required label='Changed date' name='changedDate' style={{marginTop: '20px'}}>
                                <DateInput useCoreboxFormat={true} previousVersionDate={previousVersionDate}/>
                            </Form.Item>
                        </Form>
                    </Modal>
                }
            </>
        );
    }
;

export default CoreboxImagesInput;
