import React, { useEffect } from "react";
import { Form, Input } from "antd";
import CoreboxImagesInput from "./CoreboxImagesInput";

const CoreboxForm = ({ id, ...props }) => {
  useEffect(() => {
    props.form.setFieldsValue(props.initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.form]);

  const inputProps = props.disabled ? { variant: "borderless" } : {};

  return (
    <Form className="max-h-[80vh] overflow-auto" layout="vertical" {...props}>
      <div className="flex gap-5 w-full">
        <Form.Item className="grow" required name="coreNumber" label="Core number">
          <Input {...inputProps} />
        </Form.Item>
        <Form.Item className="grow" required name="coreboxNumber" label="Corebox number">
          <Input {...inputProps} />
        </Form.Item>
      </div>
      <Form.Item name="itemDescription" label="Item description">
        <Input.TextArea {...inputProps} />
      </Form.Item>
        <CoreboxImagesInput showVersions={props.disabled} id={id} photos={props.initialValues.photos}/>
    </Form>
  );
};

export default CoreboxForm;
