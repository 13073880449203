import { Image, Skeleton } from 'antd';
import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import Dragger from 'antd/es/upload/Dragger';
import ImageGallery from './ImageGallery';

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
export const ImageThumbnail = ({url, dataBase64, loading}) => {
    if (loading) {
        return <Skeleton.Image active />
    }
    if (dataBase64) {
        return <Image src={dataBase64} height={80} />
    }

    if (url) {
        return <Image src={url} height={80} />
    }
}
const ImageInput = ({ id, value = {}, disabled, showVersions, onChange }) => {
    const [loadingNewImage, setLoadingNewImage] = useState(false)
    const {url, dataBase64, version} = value || {}

    if (showVersions) {
        return <ImageGallery url={url} id={id} version={version} />
    }

    return (
        <>
            <div className={`flex gap-3`}>
                <ImageThumbnail loading={loadingNewImage} url={url} dataBase64={dataBase64} />
                {!(showVersions || disabled) && (
                <Dragger customRequest={async ({ file, ...args }) => {
                    setLoadingNewImage(true)
                    const base = await getBase64(file)
                    setLoadingNewImage(false)
                    return onChange({fileName: file.name, dataBase64: base})
                }}
                    fileList={[]}
                >
                    <PlusOutlined className='mr-2' />
                    {url ? 'Upload new version' : 'Upload'}
                </Dragger>
            )}
            </div>

        </>
    );
};

export default ImageInput;
