import React, {useEffect} from "react";
import {Form, Modal} from "antd";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {createCorebox} from "../../api";
import {transformValues, useErrorHandlers} from "./EditCoreboxModal";
import CoreboxForm from "./CoreboxForm";

const CreateCoreboxModal = ({open, onCancel}) => {
    const queryClient = useQueryClient();

    const {mutateAsync, isPending} = useMutation({
        mutationFn: createCorebox,
        onSuccess: () => {
            queryClient.invalidateQueries();
        },
    });
    const [form] = Form.useForm();
    const {disabled, handleValuesChange, catchError} = useErrorHandlers(form);

    useEffect(() => {
        form.resetFields()
    }, [form, open])

    const handleFinish = async (values) => mutateAsync(transformValues(values)).then(onCancel).catch(catchError)

    return (
        <Modal width='800px' style={{top: '20px'}} open={open} onCancel={onCancel} title="Add Corebox"
               onOk={form.submit} okButtonProps={{loading: isPending, disabled}}>
            <CoreboxForm form={form} initialValues={{changedDate: new Date()}} onFinish={handleFinish}
                         onValuesChange={handleValuesChange}/>
        </Modal>
    );
};

export default CreateCoreboxModal;
