import React from 'react';
import _ from 'lodash';
import { Modal as AntdModal, Form, Spin } from 'antd';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getModel, updateModel } from '../api';
import ModelForm from './ModelForm';
import { useWatch } from 'antd/es/form/Form';

// const hasValidImages = (images) => images.every((image) => image === undefined) || images.every(image => image !== undefined)
const hasValidImages = (images) => images.every(image => image !== undefined)

export const transformValues = ({images, ...values}) => {
    return {
        ...values,
        frontPhoto: _.assign(images.frontPhoto, { changedDate: images.changedDate, photoSide: "Front" }),
        backPhoto: _.assign(images.backPhoto, { changedDate: images.changedDate, photoSide: "Back" })
    }
}

export const useErrorHandlers = (form) => {
    const itemNumber = useWatch('itemNumber', { form })
    const customerNumber = useWatch('customerNumber', { form })
    const modelSetNumber = useWatch('modelSetNumber', { form })

    const frontPhoto = useWatch(['images', 'frontPhoto'], { form })
    const backPhoto = useWatch(['images','backPhoto'], { form })
    const hasBothImagesChanged = hasValidImages([frontPhoto?.dataBase64, backPhoto?.dataBase64])


    return {
        disabled: !itemNumber || !customerNumber || !modelSetNumber || !hasBothImagesChanged,
        handleValuesChange: (values) => {
            Object.keys(values).forEach(field => {
                const error = form.getFieldError(field);
                if (!error.length) {
                    return;
                }

                form.setFields([{ name: field, errors: [] }]);
            });
        },
        catchError: ({ response }) => {
            form.setFields(response.data.validationErrors.map((error) => {
                switch (error) {
                    case 'Item Number already exists':
                        return { name: 'itemNumber', errors: ['Item number already exists'] }
                    case 'Model set number already exists':
                        return { name: 'modelSetNumber', errors: [error] }
                    case 'Changed Date is required.':
                        return { name: 'changedDate', errors: ['Changed date is required']}
                    case 'Front photo is required.':
                        return { name: 'frontPhoto', errors: ['Swing image is required']}
                    case 'Back photo is required.':
                        return { name: 'backPhoto', errors: ['Press image is required']}
                    default:
                        return {}

                }
            }))
        }
    }
}

const EditModal = ({ id, open, viewOnly, onCancel }) => {
    const queryClient = useQueryClient()
    const { isFetching, data } = useQuery({
        queryKey: ['model', id],
        queryFn: () => getModel(id),
        keepPreviousData: false,
    })
    const { mutateAsync, isPending: isSaving } = useMutation({
        mutationFn: updateModel,
        onSuccess: () => {
            queryClient.invalidateQueries()
        }
    })
    const {frontPhoto, backPhoto, ...rest} = data || {}
    const initialValues = data ? { ...rest,
        images: {frontPhoto, backPhoto, changedDate: data.frontPhoto?.changedDate || new Date() }
    } : {}

    const [form] = Form.useForm()
    const { disabled, handleValuesChange, catchError } = useErrorHandlers(form)

    const handleFinish = async (values) => {
        const newValues = _.omitBy(transformValues(values), (value, key) => {
            return ['frontPhoto', 'backPhoto'].includes(key) && !value?.dataBase64
        })

        mutateAsync({ id, ...newValues })
            .then(onCancel)
            .catch(catchError)
    }
    return (
        <AntdModal
            open={open}
            onCancel={onCancel}
            width='800px'
            style={{ top: '20px' }}
            title={viewOnly ? 'View model' : 'Edit model'}
            onOk={form.submit}
            okButtonProps={{ loading: isSaving, disabled, className: viewOnly ? 'hidden' : '' }}
        >
            {isFetching && <Spin className='w-full h-40 flex flex-col items-center justify-center' />}
            {!isFetching && <ModelForm disabled={viewOnly} form={form} id={id} initialValues={initialValues} onFinish={handleFinish} onValuesChange={handleValuesChange} />}
        </AntdModal>
    );
};

export default EditModal;
