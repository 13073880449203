import React, { useEffect } from 'react';
import {Form, Input, InputNumber } from 'antd';
import ImagesInput from './ImagesInput';

const INT_32_MAX_VALUE = 2147483647

const ModelForm = ({id, ...props }) => {
    useEffect(() => {
        props.form.setFieldsValue(props.initialValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.form])

    const inputProps = props.disabled ? { variant: 'borderless'} : {}

    return (
        <Form className='max-h-[80vh] overflow-auto' layout='vertical' {...props}>
            <div className='flex gap-5 w-full'>
                <Form.Item className='grow' required name="itemNumber" label="Item number">
                    <Input {...inputProps} />
                </Form.Item>
                <Form.Item className='grow' required name="customerNumber" label="Customer number">
                    <Input {...inputProps} />
                </Form.Item>
            </div>
            <Form.Item required name="modelSetNumber" label="Model set number">
                <InputNumber className='w-full' {...inputProps} max={INT_32_MAX_VALUE} />
            </Form.Item>
            <Form.Item name="itemDescription" label="Item description">
                <Input.TextArea {...inputProps} />
            </Form.Item>
            <Form.Item noStyle name='images'>
                <ImagesInput showVersions={props.disabled} id={id}/>
            </Form.Item>
        </Form>
    );
};

export default ModelForm;
