import React, { useEffect, useState } from 'react';
import { Badge, Col, Image, Modal, Pagination, Row, Spin, Tooltip } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { getPhotoVersions } from '../api';
import { FormattedDate } from './DateInput';


export const PHOTO_SIDE = {
    FRONT: 'Front',
    BACK: 'Back'
}

const ImageGallery = ({ url, version, id }) => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [topPage, setTopPage] = useState(0);
    const [bottomPage, setBottomPage] = useState(0);

    const { isFetching: isFetchingFront, isFetched: isFetchedFront, data } = useQuery({
        queryKey: ['model', id, PHOTO_SIDE.FRONT],
        queryFn: () => getPhotoVersions(id, PHOTO_SIDE.FRONT),
        keepPreviousData: false,
        enabled: previewOpen,
        select: (data) => [...data].reverse()
    })

    const { isFetching: isFetchingBack, data: dataBack } = useQuery({
        queryKey: ['model', id, PHOTO_SIDE.BACK],
        queryFn: () => getPhotoVersions(id, PHOTO_SIDE.BACK),
        keepPreviousData: false,
        enabled: previewOpen,
        select: (data) => [...data].reverse()
    })

    const isFetching = isFetchingFront || isFetchingBack

    useEffect(() => {
        if (isFetchedFront) {
            setTopPage(data.length - 1)
            setBottomPage(data.length - 2)
        }
    }, [isFetchedFront, data?.length])

    if (!url) {
        return null
    }


    const totalVersions = data?.length
    const topChangedDate = data?.[topPage]?.changedDate
    const bottomChangedDate = data?.[bottomPage]?.changedDate

    return <>
        <Badge count={<Tooltip title={`${version} version${version > 1 ? 's' : ''}`}><span className='ant-badge-count'>{version}</span></Tooltip>}>
            <Image height={80}
                className='cursor-pointer'
                src={url}
                preview={false}
                onClick={() => setPreviewOpen(true)}
            />
        </Badge>
        <Modal footer={null} width='70vw' height='80vh' open={previewOpen} title={`Images`} onCancel={() => setPreviewOpen(false)}>
            {isFetching && <Spin className='w-full h-[80vh] flex flex-col items-center justify-center' />}
            {!isFetching && (

                <>
                    <div className='h-[80vh] flex flex-col justify-center'>

                        <Row justify='center' align='middle' className='h-2/5'>
                            <Col span={24} className='h-full text-center'>
                                <Image height='100%' className='border-2 border-white' src={data?.[topPage]?.url} />
                                <Image height='100%' className='border-2 border-white' src={dataBack?.[topPage]?.url} />
                            </Col>
                        </Row>

                        <Row className='mb-3'>
                            <Col span={24} className='text-center'>
                                <FormattedDate className='font-medium' value={topChangedDate} />
                            </Col>
                            <Col span={24} className='text-center'>
                                <Pagination current={topPage + 1} total={totalVersions} pageSize={1} onChange={(page) => setTopPage(page - 1)} />
                            </Col>
                        </Row>


                        {totalVersions > 1 && <>
                            <Row className='h-2/5'>
                                <Col span={24} className='text-center h-full'>
                                    <Image height='100%' className='border-2 border-white' src={data?.[bottomPage]?.url} />
                                    <Image height='100%' className='border-2 border-white' src={dataBack?.[bottomPage]?.url} />
                                </Col>
                            </Row>
                            <Row justify='space-between'>
                                <Col span={24} className='text-center'>
                                    <FormattedDate className='font-medium' value={bottomChangedDate} />
                                </Col>
                                <Col span={24} className='text-center'>
                                    <Pagination current={bottomPage + 1} total={totalVersions} pageSize={1} onChange={(page) => setBottomPage(page - 1)} />
                                </Col>
                            </Row>
                        </>
                        }
                    </div>
                </>
            )}
        </Modal>
    </>
}

export default ImageGallery;

