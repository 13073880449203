import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { DatePicker } from 'antd';
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import updateLocale from "dayjs/plugin/updateLocale";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

const format = 'YYYY-MMM-DD';
const formatCorebox = 'YYYY-MM-DD';

dayjs.extend(localeData)
dayjs.extend(weekday)
dayjs.extend(updateLocale)
dayjs.extend(utc)
dayjs.extend(timezone)

const customShortMonths = ["A", "B", "C", "D", "E", "F", "G", "H", "J", "K", "L", "M"];
const defaultShortMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const setLocale = (useCustom) => {
    if (useCustom) {
        dayjs.updateLocale('en', { monthsShort: customShortMonths });
    } else {
        dayjs.updateLocale('en', { monthsShort: defaultShortMonths });
    }
};

export const FormattedDate = ({ className, useCoreboxFormat = false, value }) => {
    const dateFormat = useCoreboxFormat ? formatCorebox : format;
    return <time className={className} dateTime={dayjs(value).format()}>{dayjs(value).format(dateFormat)}</time>;
};

const DateInput = ({ value, readOnly, onChange, useCoreboxFormat = false, previousVersionDate, ...props }) => {
    const dateFormat = useCoreboxFormat ? formatCorebox : format;

    useEffect(() => {
        setLocale(!useCoreboxFormat);
    }, [useCoreboxFormat]);

    const disabledDate = (current) => {
        if (current) {
            if (previousVersionDate) {
                return current < dayjs(previousVersionDate).startOf('day');
            }
        }
        return false;
    };

    return (
        <DatePicker
            {...props}
            allowClear={false}
            format={dateFormat}
            showTime={false}
            onChange={(value) => onChange?.(dayjs(value).utc())}
            value={dayjs.utc(value)}
            disabledDate={disabledDate}
        />
    );
};

export default DateInput;
