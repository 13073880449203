import React from 'react';
import _ from 'lodash';
import {Form, Modal as AntdModal, Spin} from 'antd';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {getCorebox, updateCorebox} from '../../api';
import CoreboxForm from './CoreboxForm';
import {useWatch} from 'antd/es/form/Form';

const hasValidImages = (images) => images.every(image => image.dataBase64 !== undefined)

export const transformValues = ({changedDate, ...values}) => {
    return values
}

export const useErrorHandlers = (form) => {
    const coreboxNumber = useWatch('coreboxNumber', {form})
    const coreNumber = useWatch('coreNumber', {form})
    const photos = useWatch('photos', {form})

    console.log(photos)

    const hasImagesChanged = hasValidImages(photos || [])

    return {
        disabled: !hasImagesChanged || !coreboxNumber || !coreNumber,
        handleValuesChange: (values) => {
            Object.keys(values).forEach(field => {
                const error = form.getFieldError(field);
                if (!error.length) {
                    return;
                }

                form.setFields([{name: field, errors: []}]);
            });
        },
        catchError: ({response}) => {
            form.setFields(response.data.validationErrors.map((error) => {
                switch (error) {
                    case 'Core number already exists':
                        return {name: 'coreNumber', errors: [error]}
                    case 'Corebox number already exists':
                        return {name: 'coreboxNumber', errors: [error]}
                    case 'Changed Date is required.':
                        return {name: 'changedDate', errors: ['Changed date is required']}
                    default:
                        return {}

                }
            }))
        }
    }
}

const EditCoreboxModal = ({id, open, viewOnly, onCancel}) => {
    const queryClient = useQueryClient()
    const {isFetching, data} = useQuery({
        queryKey: ['model', id],
        queryFn: () => getCorebox(id),
        keepPreviousData: false,
    })
    const {mutateAsync, isPending: isSaving} = useMutation({
        mutationFn: updateCorebox,
        onSuccess: () => {
            queryClient.invalidateQueries()
        }
    })
    const initialValues = data ? {
        ...data,
        changedDate: data.photos[0]?.changedDate || new Date()
    } : {}

    const [form] = Form.useForm()
    const {disabled, handleValuesChange, catchError} = useErrorHandlers(form)

    const handleFinish = async (values) => {
        const newValues = _.omitBy(transformValues(values), (value, key) => {
            return ['upperPhoto', 'lowerPhoto'].includes(key) && !value?.dataBase64
        })

        mutateAsync({id, ...newValues})
            .then(onCancel)
            .catch(catchError)
    }

    return (
        <AntdModal
            open={open}
            onCancel={onCancel}
            width='800px'
            style={{top: '20px'}}
            title={viewOnly ? 'View corebox' : 'Edit corebox'}
            onOk={form.submit}
            okButtonProps={{loading: isSaving, disabled, className: viewOnly ? 'hidden' : ''}}
        >
            {isFetching && <Spin className='w-full h-40 flex flex-col items-center justify-center'/>}
            {!isFetching &&
            <CoreboxForm disabled={viewOnly} form={form} id={id} initialValues={initialValues} onFinish={handleFinish}
                         onValuesChange={handleValuesChange}/>}
        </AntdModal>
    );
};

export default EditCoreboxModal;
