import { Table as AntdTable, Button, Input, Skeleton, Tooltip } from 'antd';
import React, { useContext, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {DeleteOutlined, EditOutlined, EyeOutlined, SearchOutlined} from '@ant-design/icons';
import { getModels } from '../api';
import EditModal from './EditModal';
import _ from 'lodash';
import {AuthContext, ROLES} from '../App';
import ImageGallery from './ImageGallery';
import DeleteModal from "./DeleteModal";

const skeleton = <Skeleton.Input size='small' />
const emptyData = [
  { itemNumber: skeleton, customerNumber: skeleton, modelSetNumber: skeleton, itemDescription: skeleton },
  { itemNumber: skeleton, customerNumber: skeleton, modelSetNumber: skeleton, itemDescription: skeleton },
  { itemNumber: skeleton, customerNumber: skeleton, modelSetNumber: skeleton, itemDescription: skeleton },
]

const getColumns = (onPreview, onEdit, onDelete, getFilters, isContributor, isAdmin, filteredInfo, sortInfo) => [
  getFilters({
    title: 'Item number',
    dataIndex: 'itemNumber',
    key: 'itemNumber',
    sorter: true,
    filteredInfo,
    sortInfo,
  }),
  getFilters({
    title: 'Customer number',
    dataIndex: 'customerNumber',
    key: 'customerNumber',
    sorter: true,
    filteredInfo,
    sortInfo,
  }),
  getFilters({
    title: ' Model set number',
    dataIndex: 'modelSetNumber',
    key: 'modelSetNumber',
    sorter: true,
    filteredInfo,
    sortInfo,
  }),
  getFilters({
    title: 'Item description',
    dataIndex: 'itemDescription',
    key: 'itemDescription',
    sorter: true,
    filteredInfo,
    sortInfo,
  }),
  {
    title: 'Swing image',
    key: 'frontPhoto',
    dataIndex: 'frontPhoto',
    render: (photo, record) => <ImageGallery id={record.id} url={photo?.url} version={photo?.version} />
  },
  {
    title: 'Press image',
    key: 'backPhoto',
    dataIndex: 'backPhoto',
    render: (photo, record) => <ImageGallery id={record.id} url={photo?.url} version={photo?.version} />
  },
  {
    key: "actions",
    title: "",
    render: (_, record) => <div className='flex justify-center'>
      <Tooltip title="View model" key={`view-${record.id}`}>
        <Button className='mr-2' icon={<EyeOutlined />} onClick={() => onPreview(record)} />
      </Tooltip>
      {isContributor && <Tooltip title="Edit model" key={`edit-${record.id}`}>
        <Button className='mr-2' icon={<EditOutlined />} onClick={() => onEdit(record)} />
      </Tooltip>}
      {isAdmin && <Tooltip title="Delete model" key={`delete-${record.id}`}>
        <Button icon={<DeleteOutlined />} onClick={() => onDelete(record)} />
      </Tooltip>}
    </div>
  }
]
const Table = ({ onCreate }) => {
  const [globalSearch, setGlobalSearch] = useState('')
  const { roles } = useContext(AuthContext);
  const isContributor = roles.includes(ROLES.CONTRIBUTOR);
  const isAdmin = roles.includes(ROLES.ADMIN);
  const searchInput = useRef(null);

  const getColumnSearchProps = ({ dataIndex, title, filteredInfo, sortInfo, ...rest }) => {
    const filteredValue = filteredInfo[dataIndex] ? [filteredInfo[dataIndex].value] : ''
    return {
      dataIndex, title, ...rest,
      sortOrder: sortInfo.sortField === dataIndex ? (sortInfo.sortOrder === 1 ? 'ascend' : 'descend') : undefined,
      filteredValue: filteredValue,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div
          style={{
            padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Input
            ref={searchInput}
            placeholder={`Search ${title}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={confirm}
            style={{
              marginBottom: 8,
              display: 'block',
            }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{
            color: filtered ? '#1677ff' : undefined,
          }}
        />
      ),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
    }
  };



  const [pagination, setPagination] = useState({ first: 0, rows: 10 });
  const [filters, setFilters] = useState({});
  const [sorter, setSorter] = useState({});
  const [showEditModal, setShowEditModal] = useState(null);
  const [viewModel, setViewModel] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(null);

  const { isPending, data } = useQuery({
    queryKey: ['models', pagination.first, pagination.rows, sorter, filters, globalSearch],
    queryFn: () => getModels({
      ...pagination,
      ...sorter,
      filters,
      globalSearch,
    }),
    staleTime: 5 * 1000,
  })

  const handleGlobalSearch = (event) => {
    setGlobalSearch(event.target.value)
    setPagination({ ...pagination, first: 0 })
  }

  const handleClearAllFilters = () => {
    setGlobalSearch('')
    setPagination({ ...pagination, first: 0 })
    setFilters({})
    setSorter({})
  }

  return (
    <>
      <div className="flex justify-between w-full mb-5">
        <div>
          <Input className='w-[300px] mr-3' value={globalSearch} placeholder='Search for a model' onChange={handleGlobalSearch} />
          <Button type='text' onClick={handleClearAllFilters}>Clear all filters</Button>
        </div>

        {isContributor && <Button type='primary' onClick={onCreate}>Add model</Button>}
      </div>
      <AntdTable
        className='w-full'
        bordered
        scroll={{ y: '50vh' }}
        columns={getColumns(setViewModel, setShowEditModal, setShowDeleteModal, getColumnSearchProps, isContributor, isAdmin, filters, sorter)}
        dataSource={isPending ? emptyData : data?.items || []}
        loading={isPending}
        pagination={{ current: (pagination.first / pagination.rows) + 1, pageSize: pagination.pageSize, total: data?.totalNumberOfRecords || 0, showSizeChanger: false }}
        onChange={({ current, pageSize }, filters, sorter, extra) => {
          const first = extra.action === 'paginate' ? current - 1 : 0
          setPagination({ first: first * pageSize, rows: pageSize })
          const newFilters = _.reduce(filters, (acc, value, key) => {
            if (value) {
              const matchMode = key === 'modelSetNumber' ? 'equals' : 'contains';

              return { ...acc, [key]: { value: value[0], matchMode } }
            }
            return acc
          }, {})
          setFilters(newFilters)
          if (sorter.order) {
            setSorter({ sortField: sorter.field, sortOrder: sorter.order === 'ascend' ? 1 : -1 })
          } else {
            setSorter({})
          }
        }}

      />
      {showEditModal && <EditModal open={showEditModal} id={showEditModal.id} onCancel={() => setShowEditModal(null)} />}
      {viewModel && <EditModal viewOnly open={viewModel} id={viewModel.id} onCancel={() => setViewModel(null)} />}
      {showDeleteModal && <DeleteModal open={showDeleteModal} modelId={showDeleteModal.id} onCancel={() => setShowDeleteModal(null)}/>}
    </>
  );
};

export default Table;
