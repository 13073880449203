import React, { useEffect } from "react";
import { Modal, Form } from "antd";
import ModelForm from "./ModelForm";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createModel } from "../api";
import { transformValues, useErrorHandlers } from "./EditModal";

const CreateModal = ({ open, onCancel }) => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
        mutationFn: createModel,
        onSuccess: () => {
            queryClient.invalidateQueries();
        },
    });
    const [form] = Form.useForm();
    const { disabled, handleValuesChange, catchError } = useErrorHandlers(form);

    useEffect(()=> {
        form.resetFields()
    }, [form, open])

    const handleFinish = async (values) => mutateAsync(transformValues(values)).then(onCancel).catch(catchError)

    return (
        <Modal width='800px' style={{ top: '20px' }} open={open} onCancel={onCancel} title="Add model" onOk={form.submit} okButtonProps={{ loading: isPending, disabled }}>
            <ModelForm form={form} initialValues={{changedDate: new Date()}} onFinish={handleFinish} onValuesChange={handleValuesChange} />
        </Modal>
    );
};

export default CreateModal;
