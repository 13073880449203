import React, {useCallback, useEffect, useState} from 'react';
import {Avatar, Dropdown, Tabs} from 'antd';
import Table from './components/Table';
import CreateModal from './components/CreateModal';
import CreateCoreboxModal from './components/Corebox/CreateCoreboxModal';
import {AuthenticatedTemplate, useIsAuthenticated, useMsal} from '@azure/msal-react';
import {loginRequest} from './authConfig';
import {LogoutOutlined} from '@ant-design/icons';
import Logo from './components/Logo';
import CoreboxTable from "./components/Corebox/CoreboxTable";

export const ROLES = {
    CONTRIBUTOR: 'CONTRIBUTOR',
    STANDARD: 'STANDARD',
    ADMIN: 'ADMIN'
};

const ROLE_MAPPINGS = [
    {key: 'ModelPhoto-Contributors', role: ROLES.CONTRIBUTOR},
    {key: 'ModelPhoto-Admin', role: ROLES.ADMIN},
];

export const AuthContext = React.createContext({roles: []});

function App() {
    const [visible, setVisible] = useState(false);
    const [activeTab, setActiveTab] = useState("1");
    const {instance, accounts, inProgress} = useMsal();
    const [msalInitialized, setMsalInitialized] = useState(false);
    const isAuthenticated = useIsAuthenticated();
    const [name, setName] = useState(localStorage.getItem('name'));
    const [role, setRole] = useState(() => {
        const storedRoles = localStorage.getItem('role');
        try {
            return storedRoles ? JSON.parse(storedRoles) : [];
        } catch (error) {
            console.error('Failed to parse roles from localStorage:', error);
            localStorage.removeItem('role');
            return [];
        }
    });

    useEffect(() => {
        const initializeMsal = async () => {
            try {
                await instance.initialize();
                setMsalInitialized(true);
            } catch (error) {
                console.error('MSAL initialization failed:', error);
            }
        };

        initializeMsal();
    }, [instance]);

    const acquireTokenSilent = useCallback(async () => {
        if (accounts.length > 0 && msalInitialized) {
            const request = {
                ...loginRequest,
                account: accounts[0]
            };

            try {
                const response = await instance.acquireTokenSilent(request);
                localStorage.setItem('token', response.accessToken);
                localStorage.setItem('name', response.account.name);
                setName(response.account.name);

                const token = response.idTokenClaims;
                const roles = token.roles || [];
                const userRoles = ROLE_MAPPINGS.filter(mapping => roles.includes(mapping.key)).map(mapping => mapping.role);
                setRole(userRoles.length > 0 ? userRoles : [ROLES.STANDARD]);
                localStorage.setItem('role', JSON.stringify(userRoles));
            } catch (error) {
                console.error('Token acquisition failed:', error);
                instance.loginRedirect(loginRequest).catch(loginError => {
                    console.error('Login redirect failed:', loginError);
                });
            }
        }
    }, [accounts, instance, msalInitialized]);

    useEffect(() => {
        if (!isAuthenticated && inProgress === 'none') {
            instance.loginRedirect(loginRequest).catch(error => {
                console.error('Login redirect failed:', error);
            });
        } else {
            if(msalInitialized) {
                acquireTokenSilent();
            }
        }
    }, [isAuthenticated, inProgress, instance, acquireTokenSilent, msalInitialized]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if(msalInitialized) {
                acquireTokenSilent();
            }
        }, 45 * 60 * 1000);

        const handleFocus = () => {
            if(msalInitialized) {
                acquireTokenSilent();
            }
        };

        window.addEventListener('focus', handleFocus);

        return () => {
            clearInterval(intervalId);
            window.removeEventListener('focus', handleFocus);
        };
    }, [acquireTokenSilent, msalInitialized]);

    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    const items = [
        {
            key: '1',
            label: 'Model',
            children: <Table onCreate={() => setVisible(true)} />
        },
        // Take corebox out until next iteration
        {
            key: '2',
            label: 'Corebox',
            children: <CoreboxTable onCreate={() => setVisible(true)} />
        }
    ];

    return (
        <div className="App">
            <AuthenticatedTemplate>
                <div className='w-full bg-primary sticky py-[0.4rem]'>
                    <div className='flex flex-row justify-between items-center max-w-[1240px] px-[20px] mx-auto'>
                        <Logo className='h-[80px]'/>
                        {name && (
                            <Dropdown
                                menu={{
                                    items: [{key: 'logout', label: 'Sign out', icon: <LogoutOutlined/>}],
                                    onClick: () => {
                                        instance.logoutRedirect().catch(error => {
                                            console.error('Logout failed:', error);
                                        });
                                        localStorage.removeItem('name');
                                        localStorage.removeItem('token');
                                        localStorage.removeItem('role');
                                    }
                                }}
                            >
                                <Avatar
                                    size='large'
                                    style={{
                                        backgroundColor: 'white',
                                        color: 'black',
                                        cursor: 'pointer',
                                        borderColor: 'black'
                                    }}
                                >
                                    {name.split(' ').map((word) => word[0]).join('')}
                                </Avatar>
                            </Dropdown>
                        )}
                    </div>
                </div>
                <AuthContext.Provider value={{roles: role}}>
                    <div className="px-[20px] pt-10 max-w-[1240px] m-auto">
                        <Tabs defaultActiveKey="1" onChange={handleTabChange} items={items} />
                        {activeTab === "1" ? (
                            <CreateModal open={visible} onCancel={() => setVisible(false)}/>
                        ) : (
                            <CreateCoreboxModal open={visible} onCancel={() => setVisible(false)}/>
                        )}
                    </div>
                </AuthContext.Provider>
            </AuthenticatedTemplate>
        </div>
    );
}

export default App;
