import React from 'react';

const Logo = ({ className, color = '#FFF' }) => {
    return (
        <svg version="1.1" id="Logo" className={className} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            viewBox="0 0 239.6 239.6">
            <path className="st0" d="M120.1,238.4c-65.2,0-118.3-53.1-118.3-118.3C1.9,54.9,54.9,1.9,120.1,1.9c65.2,0,118.3,53.1,118.3,118.3
	C238.4,185.4,185.4,238.4,120.1,238.4z M120.1,9.5C59.1,9.5,9.5,59.1,9.5,120.2c0,61,49.7,110.7,110.7,110.7s110.7-49.7,110.7-110.7
	C230.8,59.1,181.2,9.5,120.1,9.5z" fill={color}/>
            <g>
                <path className="st0" d="M92.1,122.2c-1.2-2.4-3.5-3.5-4.7-4c0,0,0,0,0,0c0,0,0,0,0.1,0c1.1-0.6,3.1-1.7,4.3-3.9
		c1.2-2.3,1.2-4.1,1.2-6.4l0-10.1c0-5.1-1.3-8.9-4.1-11.4c-4-3.6-9.8-3.9-11.5-3.9h-6l-0.3,0c-0.8,0-1.7,0-2.7,0.8
		c-0.8,0.8-0.8,1.9-0.8,3v66.8c0,2.5,0.2,2.9,0.7,3.6c0.1,0.1,0.2,0.2,0.2,0.3c0.5,0.7,1.7,0.7,2.9,0.7c0.4,0,0.8,0,1.1,0
		c0.6,0,1.2,0,1.8,0c0.5,0,1.1,0,1.6,0c0.6,0,1.2,0,1.8,0c2.7,0,7.9-0.6,11.6-4.8c4-4.6,4-8,4-10.8l0-13.7
		C93.4,126.4,93.4,124.7,92.1,122.2z M85,107.9c0,1.6,0,3.1-2,5.2c-1.7,1.8-3.9,2.2-5.4,2.2l-0.3,0c-0.6,0-0.8,0-1.2-0.4
		c-0.5-0.5-0.5-1.1-0.5-2.1l0-20.2c0-0.6,0-1,0.2-1.5c0.3-0.5,1.3-0.5,2.6-0.5c2.9,0,3.5,0.4,4.5,1.4c1.7,1.7,2.1,3.4,2.1,5.8V107.9
		z M75.6,124.7c0-2.1,0-2.3,4.3-2.3c2.6,0,5.5,1.7,5.5,6.4v13.6c0,1.8-0.1,3.1-2.1,5.2c-1.9,2-4.5,2.2-5.3,2.2c-0.9,0-1,0-1.8-0.1
		c-0.1,0-0.6-0.6-0.6-2.7V124.7z" fill={color} />
                <path className="st0" d="M102.9,82.6c-2.3,0-3.7,0.1-3.7,2.2v70.9c0,2,0.9,2,3.7,2c3.1,0,4.2,0,4.2-2.3V84.9
		C107,82.6,105.5,82.6,102.9,82.6z" fill={color} />
                <path className="st0" d="M138.7,122.2c-1.2-2.4-3.4-3.5-4.6-4c0,0,0,0,0,0c0,0,0.1,0,0.1,0c1.1-0.6,3.2-1.7,4.4-3.9
		c1.2-2.3,1.2-4.1,1.2-6.4l0-10.1c0-5-1.4-8.9-4.1-11.4c-3.9-3.5-9.3-3.9-11.5-3.9h-6c-0.2,0-0.4,0-0.5,0c-0.6-0.1-1.4-0.1-2.3,0.6
		c-0.8,0.8-0.9,2.2-0.9,3.3v69.5c0,2,1.3,2,3.7,2c3.1,0,4.2,0,4.2-2.3v-32.1c0-0.4,0-0.6,0.1-0.7c0.3-0.3,1.7-0.3,4.1-0.3l0.8,0
		c1.9,0,4.6,2.3,4.6,6.4v26.9c0,0.5,0.1,1.1,0.5,1.5c0.5,0.5,1.2,0.5,2.5,0.5c0.1,0,0.2,0,0.4,0l0.4,0c2.9,0,4.2,0,4.2-2.3l0-26.9
		C139.9,126.4,139.9,124.7,138.7,122.2z M131.8,97.8v10.1c0,1.4,0,3.1-2,5.2c-1.9,2.1-4.9,2.2-5.4,2.2l-0.2,0c-0.4,0-0.9,0-1.3-0.3
		c-0.4-0.3-0.5-0.9-0.4-1.9c0-0.2,0-0.4,0-0.6V92.6c0-0.6,0-1,0.2-1.5c0.2-0.4,0.9-0.5,1.8-0.5c3.8,0,4.2,0.4,5.2,1.4
		C131.5,93.8,131.8,95.4,131.8,97.8z" fill={color} />
                <path className="st0" d="M169,82.5c-2.8,0-4.2,0.1-4.2,2.5v38.4c0,0.5-0.1,0.9-0.1,1.1c-0.1-0.3-0.3-0.8-0.6-1.9
		c-1.6-6.6-3-12-4.3-17.5c-1.4-5.5-2.7-10.9-4.4-17.5c-1.1-4.2-2-5.1-5.3-5.1c-1.9,0-3.2,0-3.2,1.6v71.3c0,2.3,1.1,2.5,4.1,2.5
		c2.7,0,4-0.1,4-2.5v-42.3c0-0.5,0.1-0.8,0.1-1.1c0.3,0.8,0.8,2.8,1.1,4.1c0.2,1.1,0.5,2.1,0.7,2.9c2.6,10.1,4.2,17.1,6.3,25.8
		c0.6,2.6,1.3,5.4,2,8.4c0.9,4,2.7,4.6,4.4,4.6c3.1,0,3.2-1.1,3.2-4.9V84.6C172.7,82.5,171.2,82.5,169,82.5z" fill={color} />
            </g>
        </svg>

    );
};

export default Logo;
