import axios from "axios";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
})

const extractData = ({data}) => data 
const getHeaders = () => ({
    headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
    }
})

export const getModel = (id) => instance.get(`model/${id}`, getHeaders()).then(extractData)
export const deleteModel = (id) => instance.delete(`model/${id}`, getHeaders()).then(extractData)
export const getModels = (data = {first: 0, rows: 10, sortField: "customerNumber", sortOrder: 1 }) => instance.post('model/filter', data, getHeaders()).then(extractData)
export const getPhotoVersions = (id, side) => instance.get(`model/${id}/photos/${side}`, getHeaders()).then(extractData)

export const createModel = (newModel) => instance.post(`model`, newModel, getHeaders()).then(extractData)
export const updateModel = (newModel) => instance.put(`model`, newModel, getHeaders()).then(extractData)


//Corebox
export const getCorebox = (id) => instance.get(`corebox/${id}`, getHeaders()).then(extractData)
export const deleteCorebox = (id) => instance.delete(`corebox/${id}`, getHeaders()).then(extractData)
export const getCoreboxes = (data = {first: 0, rows: 10, sortField: "customerNumber", sortOrder: 1 }) => instance.post('corebox/filter', data, getHeaders()).then(extractData)
export const getCoreboxPhotoVersions = (id, side) => instance.get(`corebox/${id}/photos/${side}`, getHeaders()).then(extractData)

export const createCorebox = (newCorebox) => instance.post(`corebox`, newCorebox, getHeaders()).then(extractData)
export const updateCorebox = (newCorebox) => instance.put(`corebox`, newCorebox, getHeaders()).then(extractData)

