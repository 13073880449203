import React from 'react';
import {Button, message, Modal} from 'antd';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {deleteCorebox} from '../../api';

const DeleteCoreboxModal = ({open, onCancel, coreboxId}) => {
    const queryClient = useQueryClient();

    const {mutateAsync, isPending} = useMutation({
        mutationFn: deleteCorebox,
        onSuccess: () => {
            queryClient.invalidateQueries();
            message.success('Corebox deleted successfully!');
            onCancel();
        },
        onError: (error) => {
            message.error(`Error deleting Corebox: ${error.message}`);
        }
    });

    const handleDelete = async () => {
        await mutateAsync(coreboxId);
    }

    return (
        <Modal
            title="Delete corebox"
            open={open}
            onCancel={onCancel}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={isPending} onClick={handleDelete}>
                    Delete
                </Button>,
            ]}
        >
            <p>Are you sure you want to delete this corebox?</p>
        </Modal>
    );
};

export default DeleteCoreboxModal;