import React from 'react';
import { Modal, Button, message } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteModel } from '../api';

const DeleteModal = ({ open, onCancel, modelId }) => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
        mutationFn: deleteModel,
        onSuccess: () => {
            queryClient.invalidateQueries();
            message.success('Model deleted successfully!');
            onCancel();
        },
        onError: (error) => {
            message.error(`Error deleting model: ${error.message}`);
        }
    });

    const handleDelete = async () => {
        await mutateAsync(modelId);
    }

    return (
        <Modal
            title="Delete model"
            open={open}
            onCancel={onCancel}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={isPending} onClick={handleDelete}>
                    Delete
                </Button>,
            ]}
        >
            <p>Are you sure you want to delete this model?</p>
        </Modal>
    );
};

export default DeleteModal;