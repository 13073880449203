import {Button, Image, Skeleton, Tooltip} from 'antd';
import React, {useState} from 'react';
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';
import Dragger from 'antd/es/upload/Dragger';
import CoreboxImageGallery from "./CoreboxImageGallery";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
export const ImageThumbnail = ({url, dataBase64, loading}) => {
    if (loading) {
        return <Skeleton.Image active/>
    }
    if (dataBase64) {
        return <Image src={dataBase64} height={80}/>
    }
    if (url) {
        return <Image src={url} height={80}/>
    }
}

const CoreboxImageInput = ({id, value = {}, disabled, showVersions, side, onChange, remove}) => {
    const [loadingNewImage, setLoadingNewImage] = useState(false);
    const {url, dataBase64, version} = value || {}

    if (showVersions) {
        return <CoreboxImageGallery url={url} id={id} version={version} side={value.photoSide}/>;
    }

    return (
        <>
            <div className="input-with-button">
                <ImageThumbnail loading={loadingNewImage} url={url} dataBase64={dataBase64}/>
                {!(showVersions || disabled) && (
                    <>
                        <Dragger customRequest={async ({file, ...args}) => {
                            setLoadingNewImage(true)
                            const base = await getBase64(file)
                            setLoadingNewImage(false)
                            return onChange({...value, fileName: file.name, dataBase64: base})
                        }}
                                 fileList={[]}
                        >
                            <PlusOutlined className='mr-2'/>
                            Upload
                        </Dragger>
                        {remove &&
                        <Tooltip title="Delete side">
                            <Button icon={<DeleteOutlined/>} onClick={remove}/>
                        </Tooltip>
                        }
                    </>
                )}
            </div>

        </>
    );
};

export default CoreboxImageInput;
