import React, {useEffect, useState} from 'react';
import {Badge, Col, Image, Modal, Pagination, Row, Spin, Tooltip} from 'antd';
import {useQuery} from '@tanstack/react-query';
import {getCoreboxPhotoVersions} from '../../api';
import {FormattedDate} from '../DateInput';

export const COREBOX_PHOTO_SIDE = {
    3: 'All',
    4: 'BackPlate',
    5: 'DoorPlate',
    6: 'FixedPart',
    7: 'RightPart',
    8: 'LooseParts',
    9: 'MiddlePart',
    10: 'UpperPart',
    11: 'BlowPlate',
    12: 'LowerPart',
    13: 'LeftPartOrTippingPart'
};

const CoreboxImageGallery = ({url, version, id, side}) => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [topPage, setTopPage] = useState(0);
    const [bottomPage, setBottomPage] = useState(0);

    const {isFetching, data} = useQuery({
        queryKey: ['corebox', id, side],
        queryFn: () => getCoreboxPhotoVersions(id, side),
        keepPreviousData: false,
        enabled: previewOpen,
        select: (data) => [...data].reverse()
    });

    useEffect(() => {
        if (data) {
            setTopPage(data.length - 1)
            setBottomPage(data.length - 2)
        }
    }, [data]);

    if (!url) {
        return null
    }


    const totalVersions = data?.length
    const topChangedDate = data?.[topPage]?.changedDate
    const bottomChangedDate = data?.[bottomPage]?.changedDate

    return (
        <>
            <Badge
                count={
                    <Tooltip title={`${version} version${version > 1 ? 's' : ''}`}>
                        <span className='ant-badge-count'>{version}</span>
                    </Tooltip>
                }
            >
                <Image
                    height={80}
                    className='cursor-pointer'
                    src={url}
                    preview={false}
                    onClick={() => setPreviewOpen(true)}
                />
            </Badge>
            <Modal
                footer={null}
                width='75vw'
                height='80vh'
                open={previewOpen}
                title={`Corebox Images - ${COREBOX_PHOTO_SIDE[side]}`}
                onCancel={() => setPreviewOpen(false)}
            >
                {isFetching && <Spin className='w-full h-[80vh] flex flex-col items-center justify-center'/>}
                {!isFetching && (
                    <>
                        <div className='h-[80vh] flex flex-col justify-center'>
                            <Row justify='center' align='middle' className='h-2/5'>
                                <Col span={24} className='h-full text-center'>
                                    <Image height='100%' className='border-2 border-white' src={data?.[topPage]?.url}/>
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col span={24} className='text-center'>
                                    <FormattedDate className='font-medium' value={topChangedDate}
                                                   useCoreboxFormat={true}/>
                                </Col>
                                <Col span={24} className='text-center'>
                                    <Pagination current={topPage + 1} total={totalVersions} pageSize={1}
                                                onChange={(page) => setTopPage(page - 1)}/>
                                </Col>
                            </Row>
                            {totalVersions > 1 && bottomPage < totalVersions && (
                                <>
                                    <Row justify='center' align='middle' className='h-2/5'>
                                        <Col span={24} className='h-full text-center'>
                                            <Image height='100%' className='border-2 border-white'
                                                   src={data?.[bottomPage]?.url}/>
                                        </Col>
                                    </Row>
                                    <Row justify='space-between'>
                                        <Col span={24} className='text-center'>
                                            <FormattedDate className='font-medium' value={bottomChangedDate}
                                                           useCoreboxFormat={true}/>
                                        </Col>
                                        <Col span={24} className='text-center'>
                                            <Pagination current={bottomPage + 1} total={totalVersions} pageSize={1}
                                                        onChange={(page) => setBottomPage(page - 1)}/>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </div>
                    </>
                )}
            </Modal>
        </>
    );
};

export default CoreboxImageGallery;
