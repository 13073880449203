import {Button, Input, Skeleton, Table as AntdTable, Tooltip} from 'antd';
import React, {useContext, useRef, useState} from 'react';
import {useQuery} from '@tanstack/react-query';
import {DeleteOutlined, EditOutlined, EyeOutlined, SearchOutlined} from '@ant-design/icons';
import {getCoreboxes} from '../../api';
import EditCoreboxModal from './EditCoreboxModal';
import _ from 'lodash';
import {AuthContext, ROLES} from '../../App';
import DeleteCoreboxModal from './DeleteCoreboxModal';
import CoreboxImageGallery, {COREBOX_PHOTO_SIDE} from "./CoreboxImageGallery";

const skeleton = <Skeleton.Input size='small'/>;
const emptyData = [
    {itemNumber: skeleton, customerNumber: skeleton, modelSetNumber: skeleton, itemDescription: skeleton},
    {itemNumber: skeleton, customerNumber: skeleton, modelSetNumber: skeleton, itemDescription: skeleton},
    {itemNumber: skeleton, customerNumber: skeleton, modelSetNumber: skeleton, itemDescription: skeleton},
];

const getColumns = (onPreview, onEdit, onDelete, getFilters, isContributor, isAdmin, filteredInfo, sortInfo) => [
    getFilters({
        title: 'Core number',
        dataIndex: 'coreNumber',
        key: 'coreNumber',
        sorter: true,
        filteredInfo,
        sortInfo,
    }),
    getFilters({
        title: 'Corebox number',
        dataIndex: 'coreboxNumber',
        key: 'coreboxNumber',
        sorter: true,
        filteredInfo,
        sortInfo,
    }),
    getFilters({
        title: 'Item description',
        dataIndex: 'itemDescription',
        key: 'itemDescription',
        sorter: true,
        filteredInfo,
        sortInfo,
    }),
    {
        title: 'Images',
        key: 'photos',
        dataIndex: 'photos',
        render: (photos, record) => (
            <div className="flex-image-container">
                {photos?.map((photo, index) => (
                    <Tooltip key={index} title={COREBOX_PHOTO_SIDE[photo.photoSide]}>
                        <CoreboxImageGallery id={record.id} url={photo.url} version={photo.version}
                                             side={photo.photoSide}/>
                    </Tooltip>
                ))}
            </div>
        ),
        width: '35%'
    },
    {
        key: "actions",
        title: "",
        render: (_, record) => <div className='flex justify-center'>
            <Tooltip title="View corebox" key={`view-${record.id}`}>
                <Button className='mr-2' icon={<EyeOutlined/>} onClick={() => onPreview(record)}/>
            </Tooltip>
            {isContributor &&
            <Tooltip title="Edit corebox" key={`edit-${record.id}`}>
                <Button className='mr-2' icon={<EditOutlined/>} onClick={() => onEdit(record)}/>
            </Tooltip>}
            {isAdmin && <Tooltip title="Delete corebox"
                                 key={`delete-${record.id}`}>
                <Button icon={<DeleteOutlined/>} onClick={() => onDelete(record)}/>
            </Tooltip>}
        </div>
    }
];
const CoreboxTable = ({onCreate}) => {
    const [globalSearch, setGlobalSearch] = useState('');
    const {roles} = useContext(AuthContext);
    const isContributor = roles.includes(ROLES.CONTRIBUTOR);
    const isAdmin = roles.includes(ROLES.ADMIN);
    const searchInput = useRef(null);

    const getColumnSearchProps = ({dataIndex, title, filteredInfo, sortInfo, ...rest}) => {
        const filteredValue = filteredInfo[dataIndex] ? [filteredInfo[dataIndex].value] : '';
        return {
            dataIndex, title, ...rest,
            sortOrder: sortInfo.sortField === dataIndex ? (sortInfo.sortOrder === 1 ? 'ascend' : 'descend') : undefined,
            filteredValue: filteredValue,
            filterDropdown: ({setSelectedKeys, selectedKeys, confirm}) => (
                <div
                    style={{
                        padding: 8,
                    }}
                    onKeyDown={(e) => e.stopPropagation()}
                >
                    <Input
                        ref={searchInput}
                        placeholder={`Search ${title}`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={confirm}
                        style={{
                            marginBottom: 8,
                            display: 'block',
                        }}
                    />
                </div>
            ),
            filterIcon: (filtered) => (
                <SearchOutlined
                    style={{
                        color: filtered ? '#1677ff' : undefined,
                    }}
                />
            ),
            onFilterDropdownOpenChange: (visible) => {
                if (visible) {
                    setTimeout(() => searchInput.current?.select(), 100);
                }
            },
        };
    };

    const [pagination, setPagination] = useState({first: 0, rows: 10});
    const [filters, setFilters] = useState({});
    const [sorter, setSorter] = useState({});
    const [showEditModal, setShowEditModal] = useState(null);
    const [viewCorebox, setViewCorebox] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(null);

    const {isPending, data} = useQuery({
        queryKey: ['coreboxes', pagination.first, pagination.rows, sorter, filters, globalSearch],
        queryFn: () => getCoreboxes({
            ...pagination,
            ...sorter,
            filters,
            globalSearch,
        }),
        staleTime: 5 * 1000,
    });

    const handleGlobalSearch = (event) => {
        setGlobalSearch(event.target.value);
        setPagination({...pagination, first: 0});
    };

    const handleClearAllFilters = () => {
        setGlobalSearch('');
        setPagination({...pagination, first: 0});
        setFilters({});
        setSorter({});
    };

    return (
        <>
            <div className="flex justify-between w-full mb-5">
                <div>
                    <Input className='w-[300px] mr-3' value={globalSearch} placeholder='Search for a corebox'
                           onChange={handleGlobalSearch}/>
                    <Button type='text' onClick={handleClearAllFilters}>Clear all filters</Button>
                </div>

                {isContributor && <Button type='primary' onClick={onCreate}>Add corebox</Button>}
            </div>
            <AntdTable
                className='w-full'
                bordered
                scroll={{y: '50vh'}}
                columns={getColumns(setViewCorebox, setShowEditModal, setShowDeleteModal, getColumnSearchProps, isContributor, isAdmin, filters, sorter)}
                dataSource={isPending ? emptyData : data?.items || []}
                loading={isPending}
                pagination={{
                    current: (pagination.first / pagination.rows) + 1,
                    pageSize: pagination.pageSize,
                    total: data?.totalNumberOfRecords || 0,
                    showSizeChanger: false
                }}
                onChange={({current, pageSize}, filters, sorter, extra) => {
                    const first = extra.action === 'paginate' ? current - 1 : 0;
                    setPagination({first: first * pageSize, rows: pageSize});
                    const newFilters = _.reduce(filters, (acc, value, key) => {
                        if (value) {
                            const matchMode = key === 'modelSetNumber' ? 'equals' : 'contains';
                            return {...acc, [key]: {value: value[0], matchMode}};
                        }
                        return acc;
                    }, {});
                    setFilters(newFilters);
                    if (sorter.order) {
                        setSorter({sortField: sorter.field, sortOrder: sorter.order === 'ascend' ? 1 : -1});
                    } else {
                        setSorter({});
                    }
                }}
            />
            {showEditModal &&
            <EditCoreboxModal open={showEditModal} id={showEditModal.id} onCancel={() => setShowEditModal(null)}/>}
            {viewCorebox &&
            <EditCoreboxModal viewOnly open={viewCorebox} id={viewCorebox.id} onCancel={() => setViewCorebox(null)}/>}
            {showDeleteModal && <DeleteCoreboxModal open={showDeleteModal} coreboxId={showDeleteModal.id}
                                                    onCancel={() => setShowDeleteModal(null)}/>}
        </>
    );
};

export default CoreboxTable;
