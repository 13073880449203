import { Button, Form, Modal } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import ImageInput from './ImageInput';
import DisabledContext from 'antd/es/config-provider/DisabledContext';
import DateInput, { FormattedDate } from './DateInput';
import dayjs from "dayjs";

const ImagesInput = ({ id, showVersions, value, onChange }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [disabledOkButton, setDisabledOkButton] = useState(true);
    const [form] = Form.useForm()
    const disabled = useContext(DisabledContext)

    const handleValuesChange = (_, {frontPhoto, backPhoto, changedDate}) => {
        setDisabledOkButton(!frontPhoto || !backPhoto || !changedDate)
    }

    useEffect(() => {
        form.resetFields()
    }, [form, modalOpen])

    const handleFinish = (values) => {
        onChange?.(values)
        setModalOpen(false)
    }
    
    const label = value?.frontPhoto ? 'Add new version' : 'Add images'
    const previousVersionDate = value?.changedDate ? dayjs(value.changedDate) : null

    return (
        <>
            {value?.frontPhoto && <div className='flex w-full justify-between'>
                <Form.Item required name={['images', 'frontPhoto']} label="Swing image">
                    <ImageInput showVersions={showVersions} disabled id={id} />
                </Form.Item>
                <Form.Item required name={['images', 'backPhoto']} label="Press image">
                    <ImageInput showVersions={showVersions} disabled id={id}/>
                </Form.Item>
                <Form.Item required label='Changed date'>
                    <FormattedDate value={value?.changedDate} />
                </Form.Item>
            </div>
            }
            {!disabled && <Button onClick={() => setModalOpen(true)}>{label}</Button>}
            <Modal
                open={modalOpen}
                title={label}
                onCancel={() => setModalOpen(false)}
                onOk={form.submit}
                okButtonProps={{disabled: disabledOkButton}}
            >
                <Form
                    form={form}
                    initialValues={{changedDate: new Date()}}
                    layout='vertical'
                    onFinish={handleFinish}
                    onValuesChange={handleValuesChange}
                >
                    <Form.Item required name='frontPhoto' label="Swing image">
                        <ImageInput />
                    </Form.Item>
                    <Form.Item required name='backPhoto' label="Press image">
                        <ImageInput />
                    </Form.Item>
                    <Form.Item required label='Changed date' name='changedDate'>
                        <DateInput previousVersionDate={previousVersionDate} />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default ImagesInput;
